<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form :layout="layoutValue">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="分类名称">
                <a-input v-model="queryParam.postCode" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="状态">
                  <a-select placeholder="请选择" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option
                      v-for="(d, index) in dict.type['sys_normal_disable']"
                      :key="index"
                      :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:articlecate:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['system:articlecate:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:articlecate:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type['sys_normal_disable']" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :scroll="{ y: scrollY }"
        :size="tableSize"
        rowKey="postId"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :bordered="tableBordered">
        <span slot="img" slot-scope="text, record">
          <a-avatar :src="text" shape="square" size="large">
          </a-avatar>
        </span>
        <span slot="status" slot-scope="text, record">
          <!-- <dict-tag :options="dict.type['sys_show_hide']" :value="record.status" /> -->
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="confirmHandleStatus(record)"
            @cancel="cancelHandleStatus(record)">
            <span slot="title">确认<b>{{ record.status === 1 ? '关闭' : '开启' }}</b>这个文章分类吗?</span>
            <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 1" />
          </a-popconfirm>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:articlecate:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['system:articlecate:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['system:articlecate:remove']">
            <a-icon type="delete" />删除
          </a>
          <a @click="handleArticleList(record)" v-hasPermi="['system:articlecate:remove']">
            <a-icon type="eye" />查看文章
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
// import * as article from '@/api/mall/article'
import * as articlecate from '@/api/mall/articlecate'
import GenInfoForm from '@/views/tool/gen/modules/GenInfoForm.vue'

export default {
  name: 'Post',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        postCode: undefined,
        postName: undefined,
        status: undefined
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '分类图片',
          dataIndex: 'img',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分类名称',
          dataIndex: 'label',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '显示顺序',
          dataIndex: 'sort',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询部门列表 */
    getList() {
      const response = {
        'code': '20001',
        'success': true,
        'message': '成功.',
        'data': {
          'records': [{
            'id': 1,
            'pid': 0,
            'label': '热门推荐',
            'image': '',
            'sort': 0,
            'status': 1,
            'children': [
              {
                'id': 20,
                'pid': 1,
                'label': '新闻动态',
                'image': '',
                'sort': 0,
                'status': 1
              },
              {
                'id': 21,
                'pid': 1,
                'label': '最新热点',
                'image': '',
                'sort': 0,
                'status': 1,
                'children': [
                  {
                    'id': 22,
                    'pid': 21,
                    'label': '热点1',
                    'image': '',
                    'sort': 0,
                    'status': 1
                  }, {
                    'id': 23,
                    'pid': 21,
                    'label': '热点2',
                    'image': '',
                    'sort': 0,
                    'status': 1
                  }
                ]
              }
            ]
          },
          {
            'id': 2,
            'pid': 0,
            'label': 'APP问题',
            'image': '',
            'sort': 0,
            'status': 1,
            'children': []
          }],
          'total': 8,
          'size': 0,
          'current': 1,
          'orders': [],
          'optimizeCountSql': true,
          'searchCount': true,
          'countId': null,
          'maxLimit': null,
          'pages': 0
        },
        'errors': null,
        'time': '2023-06-02 08:44:03'
      }
      this.list = response.data.records
      this.total = response.data.total
      return
      // TODO
      this.loading = true
      articlecate.list(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        postCode: undefined,
        postName: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.postId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
      this.setTableScrollY()
    },
    /* 用户状态修改 status 0开启 1关闭 */
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 1 ? '关闭' : '启用'
      const status = row.status === 1 ? 0 : 1
      // TODO return 更换接口
      return
      changeUserStatus(row.id, status).then((res) => {
        if (res.success) {
          row.status = row.status === 0 ? 1 : 0
          this.$message.success(text + '成功', 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const postIds = row.postId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + postIds + '的数据',
        onOk() {
          return articlecate.del(postIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 查看当前分类下的 文章列表 **/
    handleArticleList(info) {
      this.$router.push({
        path: '/system/system/article/index',
        query: { id: info.id }
      })
    }
  }
}
</script>
